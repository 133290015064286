import React from 'react';
import { FormattedMessage } from 'react-intl-next';

import { token } from '@atlaskit/tokens';
import { N500 } from '@atlaskit/theme/colors';

import { fg } from '@confluence/feature-gating';
import { PoweredByTemplates } from '@confluence/powered-by-templates';
import { ProfileLink, ProfileCardWrapper } from '@confluence/profile';

import { i18n } from './i18n';
import type { UserShape } from './types';

type PageOwnershipBylineContentProps = {
	owner: UserShape;
	templateId: string | null;
	abbreviateBylineText?: boolean;
};
export const PageOwnershipBylineContent = ({
	owner,
	templateId,
	abbreviateBylineText,
}: PageOwnershipBylineContentProps) => {
	const style = {
		color: token('color.text.subtle', N500),
	};

	const linkOnly = (
		<ProfileLink
			userId={owner.userId}
			fullName={owner.fullName}
			isAnonymous={owner.isAnonymous}
			referralSource="profilecard"
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			style={style}
		/>
	);
	const profileLink = fg('confluence_show_profile_card_on_name_hover') ? (
		<ProfileCardWrapper userId={owner.userId}>{linkOnly}</ProfileCardWrapper>
	) : (
		linkOnly
	);

	const bylineTextTemplate = abbreviateBylineText
		? i18n.abbreviatedBylineMainTextWithTemplate
		: i18n.bylineMainTextWithTemplate;
	const bylineTextNonTemplate = abbreviateBylineText
		? i18n.abbreviatedBylineMainText
		: i18n.bylineMainText;

	return templateId ? (
		<FormattedMessage
			{...bylineTextTemplate}
			values={{
				user: profileLink,
				withTemplate: <PoweredByTemplates templateId={templateId} />,
			}}
		/>
	) : (
		<FormattedMessage {...bylineTextNonTemplate} values={{ user: profileLink }} />
	);
};
